<template>
  <div class="comPatientModal">
    <template v-for="(item, index) in items">
      <div
        class="user_given_time pustoy"
        v-if="item.status == 'not'"
        :class="'minutes' + item.duration"
        :key="'rows-00000' + index"
      >
        <div class="given_time"></div>
      </div>
      <div
        class="user_given_time"
        v-if="item.status == 'free'"
        :class="'minutes' + item.duration"
        :key="'rows-11111' + index"
        @click="addOrder(item.from, item.to)"
      >
        <div class="given_time">{{ item.from }} - {{ item.to }}</div>
      </div>
      <div
        class="user_given_time"
        :style="{ backgroundColor: item.color, color: '#fff' }"
        style=""
        :key="'rows-22222' + index"
        v-if="item.status == 'busy'"
        :class="'minutes' + item.duration"
        @click="updateStatus(item.order_id,item.from)"
      >
        <div class="given_time">{{ item.from }} - {{ item.to }}</div>
        <div class="patient__name">{{ item.patient }}</div>
      </div>
    </template>
    <el-dialog
      :title="$t('message.add_to_reception')"
      @open="changeDialogStatus(true)"
      @close="changeDialogStatus(false)"
      :visible.sync="drawerUpdateStatus"
      width="300px"
      @closed="emptyModel"
      center
    >
      <UpdateStatus
        :waiting="loadingOrderStatus"
        :reloadStatus="reopenUpdateStatus"
        @c-close-open-update="openUpdate"
        @c-close="closeDrawer"
      ></UpdateStatus>
    </el-dialog>
    <el-drawer
      @closed="handleCreateOrderClose()"
      class="popups_title"
      @open="reopenOrder = true"
      @close="reopenOrder = false"
      :visible.sync="drawerCreateOrder"
      direction="rtl"
      size="90%"
    >
      <CreateDoctorOrder
        drawer="drawerCreateOrder"
        @c-close="closeDrawer"
        ref="addOrder"
        :time="time"
        :doctor="doctor"
        :from="from"
        :to="to"
        :today="today"
        :open="drawerCreateOrder"
      ></CreateDoctorOrder>
    </el-drawer>
    <el-drawer
      class="popups_title"
      direction="rtl"
      size="90%"
      :visible.sync="drawerUpdateOrder"
      @open="reopenUpdateOrder = true"
      @opened="drawerOpened('updateOrder')"
      @close="reopenUpdateOrder = false"
      :before-close="handleUpdateOrderModalClose"
    >
      <UpdateDoctorOrder
        :open="drawerUpdateOrder"
        :order="selectedOrder"
        drawer="drawerUpdateOrder"
        :reloadModel="reopenUpdateOrder"
        @c-close="closeDrawer"
        :selectedItem="{'doctor':doctor,'time':time,'from':from}"
        :doctor="doctor"        
        ref="updateOrder"
      ></UpdateDoctorOrder>
    </el-drawer>
  </div>
</template>
<script>
import CreateDoctorOrder from "./create-doctor-order.vue";
import UpdateDoctorOrder from "./update-doctor-order.vue";
import UpdateStatus from "./components/update-status.vue";
import { mapActions } from "vuex";

export default {
  props: {
    doctor: { type: Object, required: true },
    time: { type: String, required: true },
    today: { type: Date, required: true },
  },
  data() {
    return {
      drawerCreateOrder: false,
      drawerUpdateStatus: false,
      drawerUpdateOrder: false,
      items: [],
      from: "",
      to: "",
      order_id: "",
      patient: "",
      color: "",
      changeListener: false,
      reopenOrder: false,
      reopenUpdateStatus: false,
      reopenUpdateOrder: false,
      selectedOrder: null,
      loadingOrderStatus: false,
      bool: true,
    };
  },
  watch: {
    doctor: {
      handler: async function (newVal, oldVal) {
        this.items = [];
        this.getItems();
      },
      deep: true,
    },
  },
  mounted() {
    this.getItems();
  },
  components: {
    CreateDoctorOrder,
    UpdateStatus,
    UpdateDoctorOrder,
  },
  methods: {
    ...mapActions({
      showModel: "orders/show",
      empty: "orders/empty",
      saveList: "services/setlist",
    }),
    getItems() {
      let from = this.time;
      let to = this.tenMinPlus(this.time);
      let item = {};
      item.from = from;
      item.to = to;
      item.status = "";
      let status = "";
      for (let i = 1; i <= 6; i++) {
        status = this.getStatus(
          from,
          to,
          this.doctor.work_times,
          this.doctor.orders.data
        );
        if (item.status === "") {
          if (status == "busy") {
            item.order_id = this.order_id;
            item.patient = this.patient;
            item.color = this.color;
          }
          item.status = status;
          item.duration = 10;
        } else {
          if (item.status != status) {
            this.items.push(JSON.parse(JSON.stringify(item)));
            item.from = from;
            item.to = to;
            item.status = status;
            item.duration = 10;
            if (status == "busy") {
              item.order_id = this.order_id;
              item.patient = this.patient;
              item.color = this.color;
            }
          } else {
            if (status == "busy" && item.order_id != this.order_id) {
              this.items.push(JSON.parse(JSON.stringify(item)));
              item.from = from;
              item.to = to;
              item.status = status;
              item.duration = 10;
              item.order_id = this.order_id;
              item.patient = this.patient;
              item.color = this.color;
            } else {
              item.duration = item.duration + 10;
              item.to = this.tenMinPlus(item.to);
            }
          }
        }
        if (i == 6) {
          this.items.push(item);
        }
        from = this.tenMinPlus(from);
        to = this.tenMinPlus(to);
      }
    },
    tenMinPlus(time) {
      var result = new Date();
      result.setHours(time.split(":")[0], time.split(":")[1]);
      result.setMinutes(result.getMinutes() + 10);
      return (
        this.checkTime(result.getHours()) +
        ":" +
        this.checkTime(result.getMinutes())
      );
    },
    checkTime(i) {
      return i < 10 ? "0" + i : i;
    },
    getStatus(from, to, work_times, orders) {
      var res = 0;
      var busy = false;
      var order_id = "";
      var patient = "";
      var color = "";
      work_times.forEach(function (work_time, i) {
        if (from >= work_time.from && to <= work_time.to) res++;
      });
      if (res > 0) {
        orders.forEach(function (order, i) {
          if (from >= order.from && to <= order.to) {
            order_id = order.id;
            patient = order.patient;
            color = order.color;
            busy = true;
          }
        });
      }
      if (res) {
        if (busy) {
          this.order_id = order_id;
          this.patient = patient;
          this.color = color;
          return "busy";
        } else return "free";
      } else return "not";
    },

    addOrder(from, to) {
      this.drawerCreateOrder = true;
      this.from = from;
      this.to = to;
    },
    closeChildDrawer(drawer) {
      this[drawer] = false;
      if (this.changeListener === true) {
        this.$parent.fetchData();
        this.afterParentFetchData();
      }
    },
    closeDrawer(obj) {
      if (obj.reload) {
        this.$parent.fetchData();
      }
      if (obj.drawer) {
        this[obj.drawer] = false;
      }
    },
    async openUpdate(obj) {
      if (obj.drawer) {
        this[obj.drawer] = false;
      }
      this.selectedOrder = obj.order;
      this.drawerUpdateOrder = true;
    },
    updateStatus(order_id,from) {
      this.loadingOrderStatus = true;
      this.drawerUpdateStatus = true;
      this.from=from
      this.showModel(order_id)
        .then((res) => {
          this.loadingOrderStatus = false;
        })
        .catch((err) => {
          this.loadingOrderStatus = false;
        });
    },
    emptyModel() {
      this.empty();
    },
    emptyModelOrder() {
      this.$parent.fetchData();
    },
    handleCreateOrderClose() {
      this.empty();
      this.$refs["addOrder"].empty();
      if (this.changeListener === true) {
        this.$parent.fetchData();
        this.afterParentFetchData();
      }
    },
    handleUpdateOrderModalClose(done) {
      done();
      this.empty();
      this.$refs["updateOrder"].empty();
      if (this.changeListener === true) {
        this.$parent.fetchData();
        this.afterParentFetchData();
      }
    },
    listChanged() {
      this.changeListener = true;
    },
    changeDialogStatus(value) {
      this.reopenUpdateStatus = value;
    },
    afterParentFetchData() {
      this.changeListener = false;
    },
    drawerOpened(ref) {
      this.$refs[ref].afterOpen();
    },
  },
};
</script>
<style lang="scss">
.user_given_time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  border: 1px solid #dee2e6;
  margin: 1px;
  padding: 1px 3px;
  border-radius: 3px;
  min-height: 25px;
  // height: 27px;
  cursor: pointer;
}
.given_time {
  margin-bottom: -2px;
  font-size: 13px;
}
.modal_Pariebts_ifon {
  .info_old_patient {
    flex-direction: column;
  }
  .tel_infos {
    color: #2294ff !important;
  }
}
.comPatientModal {
  .el-dialog {
    border-radius: 5px;
  }
  .el-dialog__header {
    text-align: left;
    background-color: #efefef99;
    border-radius: 5px;
  }
  .el-dialog__headerbtn {
    top: -6px;
    right: -5px;
    background-color: #fff;
    padding: 3px 7px;
    border-radius: 50%;
  }
}
.modal_Pariebts_ifon {
  .el-radio {
    height: auto;
    margin: 0px !important;
    display: block;
    padding: 6px 0px !important;
    border: none;
  }
}
.modalButtons > .el-button {
  display: block;
  width: 100%;
  margin: 5px 0px !important;
}

/*******************color s calenda patesnts*************************/
.user_given_time {
  background-color: #d0e0ef3d;
}
.user_given_time.pustoy {
  background-color: #fff;
}
.regular {
  color: #fff;
  background-color: #409eff;
  // border-color: #409eff;
}
.cancel {
  color: #fff;
  background-color: #f56c6c;
}
.to-come {
  color: #fff;
  background-color: #67c23a;
}
.pustoy {
  border: none !important;
  cursor: not-allowed;
}
/*******************color s calenda patesnts*************************/
</style>