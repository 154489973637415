<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h" style="display: inline-block">
              <span> {{ $t("message.reception_visit") }} </span>
            </div>
            <div style="display: inline-block">
              <span class="ml-4">
                <el-tag class="mr-2"
                  >{{ $t("message.quantity") }}: {{ list.length }}</el-tag
                >
                <el-tag type="success"
                  >{{ $t("message.total_amount") }}:
                  {{ Math.round(totalAmount) | formatMoney }}</el-tag
                >
                <el-tag
                  >{{ $t("message.id_number") }}:
                  {{ this.old_order ? this.old_order.id : "" }}</el-tag
                >
              </span>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                :loading="waitingPrint"
                :disabled="saveBtn"
                @click="printDouble(printModel.id)"
                >{{ $t("message.print_double") }}</el-button
              >
              <el-button
                :loading="waitingPrint"
                :disabled="saveBtn"
                @click="print(printModel.id)"
                >{{ $t("message.print") }}</el-button
              >
              <el-button
                v-if="!old_save"
                :loading="waiting"
                :disabled="!saveBtn || !yes_user"
                @click="submit(false)"
                type="primary"
                >{{ $t("message.save") }}
              </el-button>
              <el-button
                v-if="old_save"
                :loading="waiting"
                @click="updatingOrder(false)"
                type="primary"
                >{{ $t("message.update") }}
              </el-button>
              <el-button
                :loading="waiting"
                :disabled="saveBtn"
                @click="resetForm()"
                type="success"
              >
                {{ $t("message.create_new_patient") }}
              </el-button>
              <el-button :loading="waiting" @click="closeForm()">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-tabs
      class="theePopup"
      v-model="activeTab"
      v-loading="loadingData"
      @tab-click="tabEvent"
    >
      <el-tab-pane :label="this.$t('message.basic_information')" name="first">
        <el-row>
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="grid-content">
              <div class="add_patient">
                <el-form ref="form" class="from_x">
                  <el-form-item>
                    <el-input
                      v-model="search"
                      @input="searchPatients"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-form>

                <button class="add_pats my_add_pats" @click="addPatient()">
                  {{ $t("message.add_new_patient") }}
                </button>

                <div class="ow_hiddin">
                  <div class="old_patient" v-if="yes_user">
                    <div class="info_old_patient action">
                      <a class="cursor-pointer">
                        <span
                          >{{ user.first_name }}
                          {{ user.surname }}
                          {{ user.patronymic }}</span
                        >
                        <span v-if="user.organization" class="tel_infos">
                          <i class="flaticon-call mr-1"></i>
                          {{ user.phone }}
                          <i class="flaticon-home mr-1 ml-2"></i>
                          {{ user.organization.company_name }}</span
                        >
                        <span v-else class="tel_infos">
                          <i class="flaticon-call mr-1"></i>
                          {{ user.phone }}</span
                        >
                      </a>
                      <a class="icon_ad" @click="openUpdatePatient(user)">
                        <i class="flaticon-report-1"></i>
                      </a>
                    </div>
                  </div>
                  <template v-else>
                    <div
                      class="old_patient"
                      v-for="patient in patients"
                      :key="patient.id"
                    >
                      <div class="info_old_patient">
                        <a @click="edit(patient)" class="cursor-pointer">
                          <span
                            >{{ patient.first_name }}
                            {{ patient.surname }}
                            {{ patient.patronymic }}</span
                          >
                          <span v-if="patient.organization" class="tel_infos">
                            <i class="flaticon-call mr-1"></i>
                            {{ patient.phone }}
                            <i class="flaticon-home mr-1 ml-2"></i>
                            {{ patient.organization.company_name }}</span
                          >
                          <span v-else class="tel_infos">
                            <i class="flaticon-call mr-1"></i>
                            {{ patient.phone }}</span
                          >
                        </a>
                        <a class="icon_ad" @click="openUpdatePatient(patient)">
                          <i class="flaticon-report-1"></i>
                        </a>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </el-col>
          <!-- end el-col -->

          <template v-if="user && user.id">
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="grid-content">
                <div class="add_patient">
                  <div class="name-doctor">
                    <a class="name">{{ doctor.name }}</a>
                    <span
                      v-for="specialty in doctor.specialties"
                      :key="specialty.id"
                      class="speciality"
                    >
                      {{ specialty }}
                    </span>
                  </div>

                  <div class="new_room mb-3">
                    <div class="title_new_room">
                      {{ $t("message.cabinet") }}:
                    </div>
                    <div
                      class="room_number_title"
                      v-for="item in room"
                      :key="item.id"
                    >
                      {{ item.room.room_number }}
                    </div>
                  </div>

                  <div class="title_cet mb_10">
                    {{ $t("message.condition") }}
                  </div>

                  <div class="radioMarka mb-3">
                    <el-radio-group v-model="form.condition_id">
                      <el-radio
                        v-for="condition in conditions"
                        :key="condition.id"
                        :disabled="condition.status === 'cancel'"
                        :label="condition.id"
                      >
                        {{ condition.name }}</el-radio
                      >
                    </el-radio-group>
                  </div>

                  <div class="title_cet mb-3">
                    {{ $t("message.from_hours") }}
                  </div>

                  <el-time-select
                    v-model="form.from_hour"
                    :picker-options="{
                      start: from,
                      step: '00:10',
                      end: tenMinMin(to),
                    }"
                    placeholder="время начинать"
                  >
                  </el-time-select>

                  <div class="time_of_receipt mt-4">
                    <span class="demonstration d-block mb-2">{{
                      $t("message.duration")
                    }}</span>
                    <el-radio-group v-model="form.duration">
                      <el-radio :label="10"
                        >10 {{ $t("message.minut") }}</el-radio
                      >
                      <el-radio :label="20"
                        >20 {{ $t("message.minut") }}</el-radio
                      >
                      <el-radio :label="30"
                        >30 {{ $t("message.minut") }}</el-radio
                      >
                      <el-radio :label="40"
                        >40 {{ $t("message.minut") }}</el-radio
                      >
                      <el-radio :label="50"
                        >50 {{ $t("message.minut") }}</el-radio
                      >
                      <el-radio :label="60"
                        >60 {{ $t("message.minut") }}</el-radio
                      >
                      <el-radio :label="90"
                        >90 {{ $t("message.minut") }}</el-radio
                      >
                      <el-radio :label="120"
                        >120 {{ $t("message.minut") }}</el-radio
                      >
                    </el-radio-group>
                  </div>

                  <div class="textarea mt-4">
                    <div class="sub-title">
                      {{ $t("message.notes") }}
                    </div>

                    <el-input
                      type="textarea"
                      v-model="form.notes"
                      maxlength="30"
                      show-word-limit
                    >
                    </el-input>
                  </div>
                </div>
              </div>
            </el-col>
            <!-- end el-col -->

            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="grid-content">
                <div class="add_patient">
                  <ul class="bemor_uslugalar">
                    <li
                      v-for="(item, index) in latestServices"
                      :key="'index-' + index"
                    >
                      <div>
                        {{ item.service ? item.service.name : "" }}
                      </div>
                      <span>{{ item.created_at }}</span>
                    </li>
                  </ul>
                </div>
              </div>

              <template v-if="user.organization">
                <div class="grid-content">
                  <div class="add_patient">
                    <div class="title_cet mb_10">
                      {{ $t("message.contracts_n") }}
                    </div>
                    <div class="radioMarka">
                      <el-select
                        v-model="form.contract_id"
                        :placeholder="$t('message.hidden')"
                        class="selectRoom"
                        filterable
                        clearable
                      >
                        <el-option
                          v-for="item in user.organization.company_contracts"
                          :key="item.id"
                          :label="item.number"
                          :value="item.id"
                        >
                          <span style="float: left">{{ item.number }}</span>
                          <span
                            style="
                              float: right;
                              color: #8492a6;
                              font-size: 13px;
                            "
                            >{{ item.end_date | dateFormat }}</span
                          >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
                <div class="grid-content">
                  <div class="add_patient">
                    <div class="radioMarka">
                      <el-checkbox v-model="form.payment_status_free">{{
                        $t("message.without_calculation")
                      }}</el-checkbox>
                    </div>
                  </div>
                </div>
              </template>
            </el-col>
          </template>
          <!-- end el-col -->

          <el-col v-else :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
            <div class="grid-content">
              <div class="rihgr_popups">
                <i class="icon_user"></i>
                <h1>{{ $t("message.select_patient") }}</h1>
                <h6>
                  {{ $t("message.select_patient_or_find") }}
                </h6>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>

      <el-tab-pane :label="$t('message.service')" name="second">
        <el-row class="p20">
          <el-col :xs="24" :sm="15" :md="15" :lg="15" :xl="15">
            <el-select
              v-model="patientServiceId"
              filterable
              :placeholder="$t('message.start_input_find_service')"
              class="autoInput"
              clearable
            >
              <template v-for="serviceParent in servicesSelected">
                <el-option-group
                  v-if="serviceParent.children.length > 0"
                  :key="serviceParent.id"
                  :label="serviceParent.name"
                >
                  <el-option
                    v-for="service in getChildrens(serviceParent.children)"
                    :key="service.id"
                    :label="service.name"
                    :value="service.id"
                  >
                  </el-option>
                </el-option-group>
                <el-option-group
                  v-else-if="
                    serviceParent.is_service == true &&
                    serviceParent.parent_id == null
                  "
                  :key="serviceParent.id"
                >
                  <el-option
                    :label="serviceParent.name"
                    :value="serviceParent.id"
                  >
                  </el-option>
                </el-option-group>
              </template>
            </el-select>
          </el-col>
          <!-- end col -->

          <el-col :xs="24" :sm="9" :md="9" :lg="9" :xl="9">
            <el-button
              style="font-size: 14px !important; padding: 12px !important"
              @click="addService"
              >{{ $t("message.add") }}</el-button
            >
            <el-button
              style="font-size: 14px !important; padding: 12px !important"
              @click="drawerPatientService = true"
              >{{ $t("message.add_service") }}</el-button
            >
            <el-button
              v-if="contractServiceList.length != 0 && form.contract_id"
              style="font-size: 14px !important; padding: 12px !important"
              @click="AddContactServices"
              >{{ $t("message.subject_of_agreement") }}</el-button
            >
          </el-col>
          <!-- end col -->

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <table class="table table-striped table-bordered mt-4">
              <thead>
                <tr>
                  <th>{{ $t("message.pp") }}</th>
                  <th class="wi-50">{{ $t("message.name") }}</th>
                  <th class="wi-7">{{ $t("message.express") }}</th>
                  <th class="wi-7">{{ $t("message.quantity_short") }}</th>
                  <th class="wi-7">{{ $t("message.price2") }}</th>
                  <th class="wi-7">{{ $t("message.discount_percent") }}</th>
                  <th class="wi-7">{{ $t("message.discount_price") }}</th>
                  <th class="wi-7">{{ $t("message.price2") }}</th>
                  <th class="wi-7">{{ $t("message.delete") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(ser, index) in items"
                  :key="'order-create-in-header' + index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ ser.name }}
                  </td>
                  <td>
                    <div class="d-flex">
                      <el-checkbox
                        @input="editRow(ser, $event, 'is_express')"
                        :value="ser.is_express"
                        size="small"
                        v-if="express.length"
                      />
                      <div class="px-2">
                        {{ express.length ? express[0].percent + " %" : "" }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <el-input
                      type="number"
                      id="usr"
                      size="small"
                      step="any"
                      @input="editRow(ser, $event, 'count')"
                      :value="ser.count"
                      :min="1"
                    ></el-input>
                  </td>
                  <td>
                    {{ ser.price | formatMoney }}
                  </td>
                  <td>
                    <el-input-number
                      :disabled="disableDiscount()"
                      type="number"
                      size="small"
                      @change="editRow(ser, $event, 'discount_price')"
                      controls-position="right"
                      :value="discountService(ser.discount_price)"
                      :min="0"
                      :max="100"
                    ></el-input-number>
                  </td>
                  <td>
                    <div
                      v-if="!ser.discount_price || ser.discount_price == null"
                    >
                      <crm-price-input
                        @input="editRow(ser, $event, 'price_of_discount')"
                        v-model="ser.price_of_discount"
                        :old="ser.price_of_discount"
                        size="mini"
                      >
                      </crm-price-input>
                    </div>
                    <div v-else>
                      {{
                        Math.round(
                          (discountService(ser.discount_price) *
                            ser.price *
                            ser.count) /
                            100
                        ) | formatMoney
                      }}
                    </div>
                  </td>
                  <td>
                    <span v-if="ser.discount_price">
                      {{
                        Math.round(
                          ser.count *
                            expressPrice(ser.is_express) *
                            (ser.price -
                              (ser.price *
                                discountService(ser.discount_price)) /
                                100)
                        ) | formatMoney
                      }}
                    </span>
                    <span v-else>
                      {{
                        Math.round(
                          ser.count * expressPrice(ser.is_express) * ser.price -
                            ser.price_of_discount
                        ) | formatMoney
                      }}
                    </span>
                  </td>
                  <td>
                    <el-button
                      class="d-block"
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click.native.prevent="deleteRow(ser)"
                    >
                    </el-button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <th colspan="5">{{ $t("message.total_0") }}</th>
                <th class="w-7">{{ totalDiscount | formatMoney }}</th>
                <th colspan="2">{{ Math.round(totalAmount) | formatMoney }}</th>
              </tfoot>
            </table>
          </el-col>
        </el-row>

        <el-row :gutter="25" class="p20 my-1">
          <!-- <el-col :span="12">
            <el-form ref="form">
              <div class="title_cet">{{ $t("message.material") }}</div>

              <el-select
                class="autoInput"
                v-model="form.material_id"
                filterable
                clearable
                :placeholder="$t('message.material')"
              >
                <el-option
                  v-for="item in materials"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form>
          </el-col> -->
          <el-col :span="12">
            <el-form ref="form">
              <div class="title_cet">{{ "Клинический диагноз" }}</div>
              <el-input
                v-model="form.diagnos"
                placeholder="Клинический диагноз"
              ></el-input>
            </el-form>
          </el-col>
          <!-- <el-col :span="12">
            <el-form ref="form">
              <div class="title_cet">{{ "Блок" }}</div>
              <el-input v-model="form.blok" placeholder="Блок"></el-input>
            </el-form>
          </el-col>
          <el-col :span="12">
            <el-form ref="form">
              <div class="title_cet">{{ "Количество флаконов" }}</div>
              <el-input
                v-model="form.vial_quantity"
                placeholder="Количество флаконов"
              ></el-input>
            </el-form>
          </el-col>

          <el-col :span="12">
            <el-form ref="form">
              <div class="title_cet">{{ $t("message.glass") }}</div>
              <el-input
                v-model="form.glass"
                :placeholder="$t('message.glass')"
              ></el-input>
            </el-form>
          </el-col> -->

          <el-col :span="12">
            <el-form ref="form">
              <div class="title_cet">{{ $t("message.outpatient_card") }}</div>
              <el-input
                v-model="form.outpatient_card"
                :placeholder="$t('message.outpatient_card')"
              ></el-input>
            </el-form>
          </el-col>
        </el-row>
        <el-row :gutter="25" class="p20">
          <el-col :span="8">
            <div class="my-100">
              <div class="title_cet">{{ $t("message.partner_clinic") }}</div>
              <el-form ref="form" status-icon :model="form" :rules="rules">
                <el-form-item>
                  <el-select
                    class="autoInput"
                    v-model="form.partner_clinic_id"
                    filterable
                    clearable
                    @change="filterDepartment"
                    :placeholder="$t('message.partner_clinic')"
                  >
                    <el-option
                      v-for="item in partnerClinics"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>

              <!-- <button class="add_pats" @click="drawerPartnerClinic = true">
                {{ $t("message.add_new_partner_clinic") }}
              </button> -->
            </div>
          </el-col>

          <el-col :span="8">
            <div class="my-100">
              <div class="title_cet">{{ $t("message.department") }}</div>
              <el-select
                class="autoInput"
                v-model="form.department_id"
                filterable
                clearable
                :placeholder="$t('message.department')"
              >
                <el-option
                  v-for="item in departments"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>

              <button class="add_pats" @click="drawerDepartment = true">
                {{ $t("message.add_new_department") }}
              </button>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="my-100">
              <div class="title_cet">{{ $t("message.partner_doctor") }}</div>

              <partner-doctors
                v-model="form.partner_doctor_id"
                :partner_clinic_id="form.partner_clinic_id"
              ></partner-doctors>

              <button class="add_pats" @click="drawerPartnerDoctor = true">
                {{ $t("message.add_new_partner_doctor") }}
              </button>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>

    <el-drawer
      class="popups_title"
      :append-to-body="true"
      :visible.sync="drawerCreatePatient"
      direction="rtl"
      size="60%"
    >
      <CreatePatient
        @c-close="closeDrawerPatient"
        :reopened="drawerCreatePatient"
        drawer="drawerCreatePatient"
      ></CreatePatient>
    </el-drawer>

    <el-drawer
      class="popups_title"
      @open="reopenUpdate = true"
      @close="reopenUpdate = false"
      :append-to-body="true"
      :visible.sync="drawerUpdatePatient"
      direction="rtl"
      size="60%"
    >
      <UpdatePatient
        @c-close="closeDrawerUpdatePatient"
        :reloadModel="reopenUpdate"
        :patient="selectedPatient"
        :open="drawerUpdatePatient"
        drawer="drawerUpdatePatient"
      ></UpdatePatient>
    </el-drawer>

    <el-drawer
      class="popups_title"
      :append-to-body="true"
      :visible.sync="drawerPartnerDoctor"
      direction="rtl"
      size="80%"
    >
      <AddPartnerDoctor
        @c-close="closeDrawerAddPartnerDoctor"
      ></AddPartnerDoctor>
    </el-drawer>

    <el-drawer
      class="popups_title"
      :append-to-body="true"
      :visible.sync="drawerPartnerClinic"
      direction="rtl"
      size="80%"
    >
      <AddPartnerClinic
        @c-close="closeDrawerAddPartnerDoctor"
      ></AddPartnerClinic>
    </el-drawer>

    <el-drawer
      class="popups_title"
      :append-to-body="true"
      :visible.sync="drawerDepartment"
      direction="rtl"
      size="50%"
    >
      <CreateDepartment
        @c-close="closeDrawerAddPartnerDoctor"
      ></CreateDepartment>
    </el-drawer>

    <el-drawer
      class="popups_title"
      :wrapperClosable="false"
      :append-to-body="true"
      :visible.sync="drawerPatientService"
      direction="rtl"
      size="85%"
    >
      <CreatePatientService
        :driver="drawerPatientService"
        @items="itemsChanged"
        @c-close="closeDrawerPatientService"
      ></CreatePatientService>
    </el-drawer>
    <!-- <el-drawer
      title="Изменить тег"
      :visible.sync="drawerShow"
      size="70%"
      :drawer="drawerShow"
      @open="reopenShow = true"
      :append-to-body="true"
      @close="reopenShow = false"
    >
      <div>
        <crm-show
          :reloadModel="reopenShow"
          @open="reopenShow = true"
          @c-close="reloadIfChanged('drawerShow')"
          @closed="emptyModel"
        ></crm-show>
      </div>
    </el-drawer> -->
  </div>
</template>
<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import order from "@/utils/mixins/order-with-doctor";
import users from "@/components/selects/user_list";
// import CrmShow from "./crm-show";
import drawer from "@/utils/mixins/drawer";
import partnerDoctors from "@/components/inventory-select/partner-doctor";
import CrmPriceInput from "@/components/crm/include/crm-price-input";

export default {
  mixins: [order, drawer],
  components: {
    // CrmShow,
    users,
    partnerDoctors,
    CrmPriceInput,
  },
  props: {
    doctor: { type: Object, required: true },
    time: { type: String, required: true },
    from: { type: String, required: true },
    to: { type: String, required: true },
    today: { type: Date, required: true },
  },
  data() {
    return {
      RoomName: "",
      RoomNumber: "",
      old_save: false,
      old_order: {},
      drawerShow: false,
      reopenShow: false,
      saveBtn: true,
      printModel: {},
      express_check: false,
      reopenUpdate: false,
      reloadList: false,
      room: null,
      drawerDepartment: false,
    };
  },
  watch: {
    open: {
      handler: function (newVal, oldVal) {
        if (newVal === true) {
          this.setNewOrderData();
        }
      },
      deep: true,
      immediate: true,
    },
    conditions: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.form.condition_id = this.conditions[0].id;
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.conditions.length != 0) {
      this.form.condition_id = this.conditions[0].id;
    }
  },
  mounted() {
    if (this.workTimes && this.workTimes.length === 0) this.setworkTimes();
    if (this.conditions && this.conditions.length === 0) this.loadConditions();

    if (this.express && this.express.length === 0) this.loadExpress();
    if (this.doctor.work_times.length !== 0) {
      if (this.doctor.work_times.length > 1) {
        this.room = this.doctor.work_times.filter(
          (val) => val.from <= this.from && val.to > this.from
        );
        this.room.length = 1;
      } else {
        this.room = this.doctor.work_times;
      }
    }
  },
  computed: {
    ...mapGetters({
      model: "orders/model",
      contractServiceList: "contracts/contract_services",
      workTimes: "workTimes/inventory",
      rules: "orders/rules",
      express: "expresses/list",
    }),
  },
  methods: {
    ...mapActions({
      setworkTimes: "workTimes/inventory",
      loadExpress: "expresses/index",
      editPatient: "patients/show",
      editService: "services/show",
      servicesRealtionList: "services/relationList",
      discountPatient: "discountPatients/show",
      showModel: "conclusions/show",
      save: "orders/store",
      contractServices: "contracts/contractServices",
      saveList: "services/setlist",
      update: "orders/update",
    }),
    filterDepartment(val) {
      const query = { partner_clinic_id: val };
      this.loadDepartments(query).then((res) => {
        if (!res.data.departments.data.length) this.form.department_id = null;
      });
    },
    parentEvent(value) {
      this.room = this.workTimes.filter((val) => {
        return val.day == this.form.day.getDay() && val.doctor_id == value;
      });
    },
    // async show(model) {
    //   log
    //    var name =
    //     model.id + " " + model.patient.surname + " " + model.patient.first_name;
    //   var link = document.createElement("a");

    //   link.setAttribute(
    //     "href",
    //     this.backUrl +
    //       "/file_orientation?order_id=" +
    //       model.id +
    //       "&name=" +
    //       name +
    //       "&template_id=" +
    //       template_id +
    //       "&user=" +
    //       this.token +
    //       "&status=create" +
    //       "&user_type=doctor"
    //   );
    //   link.setAttribute("target", "_blank");
    //   link.click();
    //   this.dialogClose();

    //   return;
    //   this.$loadingCursor("wait");
    //   await this.showModel(model.id)
    //     .then((res) => {
    //       this.$loadingCursor("default");
    //       this.drawerShow = true;
    //     })
    //     .catch((err) => {
    //       this.$loadingCursor("default");
    //     });
    // },
    disableDiscount() {
      if (
        this.discountPatients.length &&
        this.discountPatients[0].discount != 0 &&
        this.discountPatients[0].status
      ) {
        return true;
      } else return false;
    },

    expressPrice(is_express) {
      if (is_express) {
        return 1 + this.express[0].percent / 100;
      } else {
        return 1;
      }
    },
    discountService(sevice_discount) {
      if (
        this.discountPatients.length &&
        this.discountPatients[0].discount != 0 &&
        this.discountPatients[0].status
      ) {
        this.items.forEach((element) => {
          element.discount_price = this.discountPatients[0].discount;
        });
        return this.discountPatients[0].discount;
      } else return sevice_discount;
    },

    itemsChanged(val) {
      this.items = JSON.parse(JSON.stringify(val));
    },

    fetchData() {
      this.loadingData = true;
      const query = { relation: true };
      this.servicesRealtionList(query)
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {});
    },
    setNewOrderData() {
      this.form = JSON.parse(JSON.stringify(this.model));
      this.form.yes_doctor = true;
      this.form.doctor_id = this.doctor.id;
      this.form.day = moment(String(this.today)).format("YYYY-MM-DD");
    },
    submit(close) {
      this.changeWaiting(true);
      this.form.patient_id = this.user.id;
      this.form.services = this.list;
      this.save(this.form)
        .then((res) => {
          this.old_save = true;
          this.changeWaiting();
          this.old_order = res.data.order;
          this.saveBtn = false;
          this.$alert(res);
          this.calendarChanged();
          this.printModel = res.data ? res.data.order : null;
        })
        .catch((err) => {
          this.changeWaiting();
          this.ifValidationError(err);
        });
    },
    updatingOrder(close) {
      this.changeWaiting(true);
      this.form.id = this.old_order.id;
      this.form.patient_id = this.user.id;
      this.form.services = this.list;
      this.update(this.form)
        .then((res) => {
          this.changeWaiting();
          this.saveBtn = false;
          this.printModel = res.data ? res.data.order : null;
          this.$alert(res);
        })
        .catch((err) => {
          this.changeWaiting();
          this.ifValidationError(err);
        });
    },
    tabEvent(event) {
      if (
        event.name == "second" &&
        this.form.contract_id &&
        this.contractServiceList.length == 0
      ) {
        this.contractServices({
          id: this.form.contract_id,
          contract_service: true,
        })
          .then((res) => {})
          .catch((err) => {});
      }
    },
    AddContactServices() {
      this.saveList(this.contractServiceList.services)
        .then((res) => {})
        .catch((err) => {});
    },
    closeForm() {
      this.old_order = {};
      this.old_save = false;
      this.empty();
      this.$emit("c-close", { reload: false, drawer: this.drawer });
    },
    empty() {
      this.activeTab = "first";
      this.emptyList();
      this.emptySearch();
      this.noUser();
      this.user = null;
      this.patientServiceId = "";
      this.search = "";
      this.saveBtn = true;
      this.latestServices.order_services = [];
      this.discountPatients = [];
      this.items = [];
    },
    resetForm() {
      this.setNewOrderData();
      this.user = {};
      this.noUser();
      this.emptySearch();
      this.emptyList();
      this.old_order = {};
      this.search = "";
      this.old_save = false;
      this.saveBtn = true;
      this.latestServices = [];
      this.latestServices.order_services = [];
      this.discountPatients = [];
      this.items = [];
    },
    listChanged() {
      this.reloadList = true;
    },
    parent() {
      return this.$parent.$parent;
    },
    emptyModel() {
      this.empty_conclusions();
    },
  },
};
</script>

<style lang="scss">
.scheduler .axad tbody tr td:nth-child(1) {
  background-color: initial !important;
  min-height: 46px !important;
  min-width: 70px !important;
  border-right: initial !important;
}
.icon_ad {
  cursor: pointer;
}
.bemor_uslugalar {
  text-decoration: none;
  list-style: none;
  margin: 0px;
  padding: 0px;
  li {
    border-bottom: 1px solid #dcdfe6;
    line-height: 19px;
    padding: 5px 0;
  }
  div {
    font-weight: 600;
    font-size: 13px;
  }
  span {
    text-align: right;
    width: 100%;
    display: block;
    font-size: 12px;
  }
  .col__red span {
    color: red;
  }
}
.ow_hiddin {
  height: 50vh;
  overflow-y: scroll;
}
.ow_hiddin::-webkit-scrollbar {
  width: 8px;
}

.ow_hiddin::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #fff;
}

.ow_hiddin::-webkit-scrollbar-thumb {
  background-color: #1f75a8;
  border-radius: 20px;
}
.my_add_pats {
  margin: 10px 0 20px 0;
}
.new_room {
  display: flex;
  width: 100%;
  align-items: center;
  height: 50px;
  /* background: #dcdfe6; */
  border-bottom: 1px solid #dcdfe6;
}
</style>