<template>
  <div class="scheduler d-flex">
    <div class="filter px-3 py-4 h-100">
      <h5 class="text-secondary __label category__title">
        <i class="el-icon-info"></i>
        {{$t('message.speciality')}}
      </h5>
      <div class="category_select">
        <el-select
          class="w-100"
          size="small"
          v-model="filterForm.specialties"
          multiple
          clearable
          filterable
          allow-create
          default-first-option
          :placeholder="$t('message.chose_a_speciality')"
        >
          <el-option v-for="item in specialties" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>

      <el-calendar class="mt-4" v-model="today"></el-calendar>
    </div>
    <!-- /filter -->
    <div class="schedule ml-1">
      <div class="crm-schedule-header">
        <div class="caldata">{{ today | moment("D MMMM Y") }} Г.</div>
      </div>
      <!-- /.crm-scheduler-header -->
      <div class="crm-schedule-box d-inline-flex w-100">
        <table class="crm-schedule-table table-bordered" v-loading="loadingData">
          <thead>
            <tr>
              <th></th>
              <th v-for="(doctor,key) in list" :key="'day' + key">
                <div class="media">
                  <div class="media-left">
                    <a href="#" class="img__doctor">
                      <img class="media-object" :src="doctor.image" />
                    </a>
                  </div>
                  <div class="media-body doctor-infos user-specialty-names">
                    <p class="media-heading">{{ doctor.name }}</p>
                    <div v-for="specialty in doctor.specialties" :key="specialty.id">{{ specialty }}</div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(time,keyTime) in hours" :key="keyTime">
              <td>{{ time.time }}</td>
              <td v-for="(doctor,key) in list" :key="'task' + key">
                <DoctorTimes :doctor="doctor" :time="time.time"  :today="today"></DoctorTimes>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- /.crm-scheduler-table -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import DoctorTimes from "./doctor-times.vue";
export default {
  data() {
    return {
      today: new Date(),
      filterForm: {},
      loadingData: false
    };
  },
  watch: {
    filterForm: {
      handler: async function(newVal, oldVal) {
        await this.updateFilter(newVal);
        this.fetchData();
      },
      deep: true
    },
    today: {
      handler: async function(newVal, oldVal) {
        this.filterForm.date = moment(this.today).format("YYYY-MM-DD");
        if (this.today.getDay() == 0) this.filterForm.day = 7;
        else this.filterForm.day = this.today.getDay();
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      list: "schedules/list",
      specialties: "specialties/list",
      filter: "schedules/filter",
      hours: "hours/list"
    })
  },
  created() {
    this.filterForm = JSON.parse(JSON.stringify(this.filter));
  },
  async mounted() {
    $(".app-main")
      .last()
      .addClass("topClass");
    this.fetchData();

    if (this.specialties && this.specialties.length === 0)
      await this.loadSpecialties();

    if (this.hours && this.hours.length === 0) await this.loadHours();

    $(document).ready(function() {
      $(".crm-schedule-table tbody").scroll(function(e) {
        //detect a scroll event on the tbody
        $(".crm-schedule-table thead").css(
          "left",
          -$(".crm-schedule-table tbody").scrollLeft()
        ); //fix the thead relative to the body scrolling
        $(".crm-schedule-table thead th:nth-child(1)").css(
          "left",
          $(".crm-schedule-table tbody").scrollLeft()
        ); //fix the first cell of the header
        $(".crm-schedule-table tbody td:nth-child(1)").css(
          "left",
          $(".crm-schedule-table tbody").scrollLeft()
        ); //fix the first column of tdbody
      });
    });
  },
  components: {
    DoctorTimes
  },
  methods: {
    ...mapActions({
      updateList: "schedules/index",
      loadSpecialties: "specialties/index",
      updateFilter: "schedules/updateFilter",
      loadHours: "hours/index"
    }),
    fetchData() {
      const query = { ...this.filter };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then(res => {
            this.loadingData = false;
          })
          .catch(err => {
            this.loadingData = false;
          });
      }
    }
  },
  destroyed() {
    $(".app-main")
      .last()
      .removeClass("topClass");
  }
};
</script>
<style lang="scss">
.doctor-infos {
  div {
    font-size: 11px;
    line-height: 12px;
    margin-top: 1px;
  }
}
.scheduler {
  .filter {
    width: 320px;
    background-color: #ffffff;
    .__label {
      font-size: 1rem;
    }
    .el-calendar__header {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0;
      border-bottom: 0px;
      .el-calendar__button-group {
        margin-top: 1rem;
        button:nth-child(odd) {
          span {
            display: none;
          }
        }
        button:first-child:before {
          content: "Пред";
        }
        button:last-child:before {
          content: "След";
        }
      }
    }
    .el-calendar__body {
      text-align: center;
      padding: 0%;
      font-size: 0.8rem;
      .el-calendar-day {
        height: 2rem;
      }
    }
  }
  .schedule {
    width: calc(100vw - 362px);
    background-color: #fff;
    .media {
      .media-left {
        width: 50px;
        img {
          width: 100%;
          border-radius: 50%;
        }
      }
      .media-body {
        margin-left: 0.5rem;
        p {
          font-weight: 700;
          margin: 0;
          color: #000;
          font-size: 14px;
        }
      }
    }
  }
}

.scheduler table.crm-schedule-table {
  position: relative;
  overflow: hidden;
  border-collapse: collapse;
  width: 100%;
  /*thead*/
}
.scheduler table.crm-schedule-table > thead {
  position: relative;
  display: block;
  width: 1200px;
  overflow: visible;
}

.scheduler table.crm-schedule-table > thead th {
  min-width: 200px;
  height: 70px;
}

.scheduler table.crm-schedule-table > thead th:nth-child(1) {
  position: relative;
  min-width: 80px !important;
  display: block;
  background-color: #ffffff;
  border: none;
  border-top: 1px solid #dee2e6;
  min-width: 80px !important;
  border-right: 1px solid #dee2e6;
  background-color: #869dab29;
}

/*tbody*/
.scheduler table.crm-schedule-table > tbody {
  position: relative;
  display: block;
  width: calc(100vw - 362px);
  height: calc(100vh - 225px);
  overflow: scroll;
}

.scheduler table.crm-schedule-table > tbody td {
  min-width: 200px;
}

.scheduler table.crm-schedule-table > tbody tr td:nth-child(1) {
  position: relative;
  display: block;
  min-height: 80px;
  background-color: #869dab29;
  border: none;
  border-top: 1px solid #dee2e6;
  min-width: 80px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  border-right: 1px solid #dee2e6;
  font-weight: 600 !important;
}

/********************claendar height************************/
.scheduler table.crm-schedule-table {
  tbody tr td:nth-child(1) {
    height: 304px;
  }
}
/******** add click modal height ***********/
.comPatientModal {
  height: 301px;
}
.minutes10 {
  height: 50px;
}
.minutes20 {
  height: 100px;
}
.minutes30 {
  height: 150px;
}
.minutes40 {
  height: 200px;
}
.minutes50 {
  height: 250px;
}
.minutes60 {
  height: 300px;
}
.patient__name {
  text-transform: capitalize;
}
.user_given_time {
  font-weight: 600;
}
/********************************************/

.crm-schedule-header {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  background-color: #1f75a8;
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 18px;
}
.crm-minut-calendar div {
  margin: 0 10px;
  font-size: 14px;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

//11 Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
.theePopup tbody td {
  min-width: initial !important;
}
.theePopup tbody tr td:nth-child(1) {
  height: initial !important;
}
.theePopup tbody tr td:nth-child(1) {
  min-height: 46px !important;
  min-width: 70px !important;
  border-right: initial !important;
}
.time_of_receipt > .el-radio-group > .el-radio {
  margin-right: 10px;
}
.img__doctor {
  display: block;
  width: 45px;
  height: 45px;
  img {
    width: 100% !important;
    height: 100% !important;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.user-specialty-names {
  overflow: hidden;
  width: 100px;
  margin-right: 10px;
  div {
    width: 300px;
  }
}
.scheduler {
  .el-calendar-table td.is-selected {
    background-color: #64c101;
    color: #fff;
    font-weight: 600 !important;
  }
}
</style>