<template>
    <div class="modal_Pariebts_ifon" v-loading="loading || waiting">
        <div class="info_old_patient">
            <span>{{ (order.patient) ? order.patient.first_name : '' }} {{ (order.patient) ? order.patient.surname : '' }} {{ order.patient ? order.patient.patronymic : '' }}</span>
            <span class="tel_infos"> <i class="flaticon-call mr-1"></i> {{ order.patient ? order.patient.phone : ''}}</span>
        </div>
        <div>
            <el-radio-group v-model="form.condition_id" @change="submit">
                <el-radio v-for="condition in conditions" :key="condition.id" :label="condition.id">{{ condition.name }}</el-radio>
            </el-radio-group>
        </div>
        <el-row class="modalButtons">
            <el-button type="primary" @click="updateButton()">Изменить</el-button>
            <el-button @click="resetForm()">Закрыты</el-button>
        </el-row>
    </div>
</template>
<script>
    import {mapGetters,mapActions} from 'vuex'
    export default {
        props: {
            waiting: {type: Boolean, default: false},
        },
        data() {
            return {
                loading: false,
                form:{}
            }
        },
        watch: {
            order: {
                handler: function(){
                    this.form = JSON.parse(JSON.stringify(this.order))
                },
                deep:true,
                immediate:true
            }
        },
        computed: {
            ...mapGetters({
                order: 'orders/model',
                conditions: 'conditions/list',
            }),
        },
        async mounted() {
            if (this.conditions && this.conditions.length === 0)await this.loadConditions()
        },
        methods: {
            ...mapActions({
                loadConditions: "conditions/index",
                save: 'orders/updateStatus',
            }),
            submit(){
                this.changeLoading(true);
                this.save(this.form)
                    .then(res => {
                        this.changeLoading()
                        this.$alert(res);
                        if (close)
                            this.$emit('c-close',{reload: true, drawer: 'drawerUpdateStatus'});
                            this.$emit('closed');
                    })
                    .catch(err => {
                        this.changeLoading()
                        this.$alert(err);
                    });
            },
            changeLoading(def = false){
                this.loading = def;
            },
            resetForm() {
                this.$emit('c-close',{drawer: 'drawerUpdateStatus'});
                this.$emit('closed');
            },
            updateButton(){
                this.$emit('c-close-open-update',{order : this.order,drawer: 'drawerUpdateStatus'});
            }
        },
    }
</script>